import React from 'react';
import { MobileLayout } from "src/components";

import { GuideLoyalty } from "../../components/GuideLoyalty";
import { useLoyaltyState } from '../../hooks';
import { ILoyaltyListPageProps } from "../../types";
import { LoyaltyContent } from "./LoyaltyContent";

const LoyaltyListPage: React.FC<ILoyaltyListPageProps> = ({
  chakra,
  footerProps,
  handleBack,
  useOuterContainer,
  resourceProps,
  goToBeliPakan,
}) => {
  const { isActivated, handleStartLoyalty } = useLoyaltyState(resourceProps?.meta?.headers?.Authorization);

  return (
    <MobileLayout
      chakra={chakra?.layout}
      footerProps={footerProps}
      useOuterContainer={useOuterContainer}
      data-testid="loyalty-home-page"
    >
      {!isActivated ? (
        <GuideLoyalty 
          onArrowBack={handleBack} 
          onStart={handleStartLoyalty}
          data-testid="guide-loyalty"
        />
      ) : (
        <LoyaltyContent 
          onArrowBack={handleBack} 
          meta={resourceProps?.meta}
          goToBeliPakan={goToBeliPakan}
        />
      )}
    </MobileLayout>
  );
};

export default LoyaltyListPage;