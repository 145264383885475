import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex,Text, VStack } from "@efishery/onefish";
import React from 'react';

import { IHistoryItemProps, IPointsHistoryItem, IPointsHistoryListProps } from '../../types';

const NO_POINTS_MESSAGE = "Kamu belum punya riwayat poin. Ayo kumpulkan & tukarkan poinmu!";

const RiwayatItem: React.FC<IHistoryItemProps> = ({ reward_id, title, created_at, points, isPositive, showDetails = false, handleCheckReward }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    p={4}
    bg="white"
    borderBottom="1.5px solid"
    borderColor="gray.100"
  >
    <Flex flexDirection="column">
      <Text fontSize="sm" color="gray.900">{title}</Text>
      <Text fontSize="xs" color="gray.400">{created_at}</Text>
      {showDetails && handleCheckReward && (
        <Text fontSize="sm" color="primary.500" mt={1} onClick={() => handleCheckReward({reward_id})}>Cek detail</Text>
      )}
    </Flex>
    <Text
      fontSize="sm"
      fontWeight="medium"
      color={isPositive ? "gray.900" : "red.500"}
    >
      {isPositive ? '+' : '-'} {Math.abs(points)} Poin
    </Text>
  </Flex>
);

const getTitle = (state: string, name: string): string => {
  switch (state) {
    case 'issuance':
      return 'Dapat poin dari belanja';
    case 'expired':
      return 'Poin kedaluwarsa';
    case 'redemption':
      return `Poin ditukar: ${name}`;
    case 'refund':
      return 'Pengembalian poin';
    default:
      return 'Transaksi poin';
  }
};

const mapPointsHistoryToRiwayatItems = (pointsHistory: IPointsHistoryItem[] | null): IHistoryItemProps[] => {
  if (!pointsHistory) {
    return [];
  }
  
  return pointsHistory.map(item => ({
    title: getTitle(item.state, item.reward_name),
    name: item.reward_name,
    created_at: new Date(item.created_at).toLocaleDateString('id-ID', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }),
    points: item.points,
    isPositive: item.points > 0,
    showDetails: item.state === 'redemption',
    id: item.id,
    reward_id: item.reward_id,
  }));
};

export const RiwayatLoyalty: React.FC<IPointsHistoryListProps> = ({ pointsHistory, onClose, handleCheckReward = () => null }) => {
  const riwayatItems = mapPointsHistoryToRiwayatItems(pointsHistory);

  return (
    <Box w="full" data-testid="riwayat-loyalty">
      <Flex justifyContent="space-between" alignItems="center" p={4} bg="white">
        <Text fontSize="xl" fontWeight="semibold" color="gray.900">Riwayat Poin</Text>
        <CloseIcon onClick={onClose} fontSize={16} color="primary.500" />
      </Flex>
      
      {(pointsHistory.length === 0) ? (
        <Box w="full" bg="white" p={4} justifyItems="center" alignItems="center" data-testid="no-points-message">
          <Text>{NO_POINTS_MESSAGE}</Text>
        </Box>
        ) : (
        <Box flexGrow={1} overflowY="auto" maxH="70vh">
          <VStack align="stretch" spacing={0} data-testid="riwayat-items-list">
            {riwayatItems.map((item, index) => (
              <RiwayatItem 
                key={pointsHistory[index].id || index}
                data-testid={`riwayat-item-${index}`}
                {...item}
                handleCheckReward={() => handleCheckReward(item)}
              />
            ))}
          </VStack>
        </Box>
      )}
      
      <Box p={4} bg="white" boxShadow="sm">
        <Button
          w="full"
          bg="primary.500"
          color="white"
          py={3}
          px={4}
          borderRadius="lg"
          fontSize="md"
          fontWeight="medium"
          onClick={onClose}
          data-testid="close-button"
        >
          Tutup
        </Button>
      </Box>
    </Box>
  );
};