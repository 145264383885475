import { Box, Button, Flex, Image, Text, useBreakpointValue } from "@efishery/onefish";
import mainKv from "src/assets/images/img_kv_loyalty.jpg";
import { MobileHeader } from "src/components/Header/mobile";
import { ENV } from "src/configs/env";
import { contentsGuide } from "src/features/Loyalty/constant";

interface GuideLoyaltyProps {
  onStart: () => void;
  onArrowBack: () => void;
  mainImage?: string;
}

export function GuideLoyalty({ onStart, onArrowBack, mainImage = mainKv }: GuideLoyaltyProps) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  
  return (
    <Flex 
      flexDirection="column" 
      minHeight="100vh" 
      bg="#FAFAFA"
      data-testid="guide-loyalty"
    >
      <MobileHeader
        transparent
        onPreviousClick={onArrowBack}
        chakra={{ colorScheme: "white" }}
      />

      <Box position="relative" width="100%" paddingTop="114%">
        <Image
          src={`${ENV.APP_HOST}${mainImage}`}
          alt="Main KV"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          objectFit="cover"
          data-testid="image-main"
        />
      </Box>

      <Flex 
        flexDirection="column" 
        bg="black" 
        color="white" 
        padding="1rem"
        flex="1"
        position="relative"
        paddingBottom="62px"
        data-testid="txt-guide-content"
      >
        <Text
          fontWeight="700"
          fontSize="x-large"
          textAlign="center"
          mb="0.5rem"
          data-testid="txt-guide-title"
        >
          Cara Dapatkan Poin
        </Text>

        <Flex justifyContent="space-between" mb="1rem">
          {contentsGuide.map((content, index) => (
            <Image 
              key={index}
              src={`${ENV.APP_HOST}${content.image}`} 
              alt={`Step ${index + 1}`} 
              borderRadius="8px"
              width="30%"
              height="auto"
              aspectRatio="1.18"
              objectFit="cover"
              data-testid={`image-guide-${index}`}
            />
          ))}
        </Flex>

        <Flex justifyContent="space-between" mb="2rem">
          {contentsGuide.map((content, index) => (
            <Text 
              key={index}
              width="30%" 
              textAlign="center" 
              fontSize={["medium", "medium", "large"]}
              px="1"
              data-testid={`step-guide-${index}`}
            >
              {content.title}
            </Text>
          ))}
        </Flex>

        <Button
          mt={isMobile ? "auto" : "0"}
          position={["fixed", "fixed", "static"]}
          bottom={["0", "0", "auto"]}
          left={["0", "0", "auto"]}
          width={["calc(100% - 2rem)", "calc(100% - 2rem)", "100%"]}
          margin={["1rem", "1rem", "0"]}
          bg="#038767"
          color="white"
          borderRadius="8px"
          fontWeight="500"
          fontSize="16px"
          height="46px"
          onClick={onStart}
          zIndex={["1", "1", "auto"]}
          data-testid="button-start"
        >
          Buka Halaman Poin
        </Button>
      </Flex>
    </Flex>
  );
}