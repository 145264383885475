import { useCallback, useEffect,useState } from 'react';
import { SERVICE } from 'src/configs/service';
import { useOne } from 'src/hooks';
import { MetaQuery } from 'src/libs/dataProvider/types';

import { IRewardDetailResponse, IRewardItem } from '../types';

export interface IUseLoyaltyDetailProps {
  meta: MetaQuery;
  initialRewardItem?: IRewardItem;
}

export const useLoyaltyDetail = ({ meta, initialRewardItem }: IUseLoyaltyDetailProps) => {
  const [rewardId, setRewardId] = useState<string | null>(null);
  const [loyaltyDetailData, setLoyaltyDetailData] = useState<IRewardItem | null>(
    initialRewardItem || null
  );

  const { data, isLoading, isError, refetch } = useOne<IRewardItem>({
    apiUrl: SERVICE.PROMO_V2,
    resource: 'v1/loyalties/rewards',
    id: rewardId || '',
    meta,
    enabled: !!rewardId, // Only enable when rewardId is available
  });

  useEffect(() => {
    if (initialRewardItem) {
      if (!initialRewardItem.image_url) {
        fetchLoyaltyDetail(initialRewardItem.reward_id as unknown as string);
      } else {
        setLoyaltyDetailData(initialRewardItem);
      }
    }
  }, [initialRewardItem]);

  useEffect(() => {
    if ((data as IRewardDetailResponse)?.success) {
      setLoyaltyDetailData((data as IRewardDetailResponse)?.data);
    }
  }, [data]);

  const fetchLoyaltyDetail = useCallback(async (reward_id: string) => {
    await setRewardId(reward_id);
    refetch();
  }, [refetch]);

  return {
    data: loyaltyDetailData,
    isLoading,
    isError,
    fetchLoyaltyDetail,
  };
};