import { CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading, HStack, Image, Text, VStack } from "@efishery/onefish";
import React from 'react';
import coin from "src/assets/icons/ic_loyalty.svg";
import { ENV } from "src/configs/env";
import { MetaQuery } from "src/libs/dataProvider/types";

import { useLoyaltyDetail } from '../../hooks/useLoyaltyDetail';
import { IRewardItem } from "../../types";

interface DetailRewardProps {
  onClose: () => void;
  rewardItem: IRewardItem;
  meta: MetaQuery;
}

export const DetailReward: React.FC<DetailRewardProps> = ({ onClose, meta, rewardItem: initialRewardItem }) => {
  const { data: loyaltyDetailData, isLoading, isError } = useLoyaltyDetail({ meta, initialRewardItem });

  if (isLoading) return <div data-testid="loading-state">Loading...</div>;
  if (isError && !initialRewardItem?.image_url) {
    return (
      <VStack p={4} data-testid="error-state">
        <Text data-testid="error-message">
          Error loading reward details {initialRewardItem.name}
        </Text>
        <Box
          width="100%"
          bg="white"
          data-testid="error-button-container"
        >
          <Button
            width="100%"
            height="48px"
            bg="#038767"
            color="white"
            borderRadius="8px"
            fontWeight={500}
            fontSize="md"
            letterSpacing="0.5px"
            onClick={onClose}
            data-testid="error-button"
          >
            Tutup
          </Button>
        </Box>
      </VStack>
    );
  }

  return (
    <Box
      bg="white"
      position="relative"
      data-testid="bottom-sheet-detail-reward"
    >
      <VStack align="stretch" spacing={4}>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        p={2}
        width="100%"
        bg="white"
        data-testid="title-section"
      >
        <Heading as="h1" fontWeight={600} size="md" data-testid="detail-reward-title">
          Detail Hadiah
        </Heading>
        <CloseIcon color="#038767" onClick={onClose} data-testid="close-icon" cursor="pointer" />
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        gap="20px"
        pb={4}
        borderBottom="1px solid #D2D2D2"
        data-testid="reward-info-section"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100px"
          height="100px"
          background="#F7F7F7"
          borderRadius="20px"
        >
          <Image
            src={loyaltyDetailData?.image_url}
            alt={loyaltyDetailData?.name}
            width="80px"
            height="80px"
            objectFit="contain"
            data-testid="reward-image"
          />
        </Flex>

        <VStack
          alignItems="flex-start"
          width="3/4"
          padding={2}
        >
          <Heading fontWeight={600} size="md" as="h2" data-testid="reward-name">
            {loyaltyDetailData?.name}
          </Heading>

          <VStack alignItems="flex-start">
            <HStack spacing="8px">
              <Image
                src={`${ENV.APP_HOST}${coin}`}
                data-testid="loyalty-coin-image"
                boxSize="6"
              />
              <Text
                fontWeight={400}
                fontSize="sm"
                color="#A5A5A5"
                data-testid="points-required-label"
              >
                Poin yang Dibutuhkan
              </Text>
            </HStack>

            <Text fontSize="sm" ml="8" data-testid="points-required-value">
              {loyaltyDetailData?.points} poin
            </Text>
          </VStack>
        </VStack>
      </Flex>
        <VStack width="full" alignItems="flex-start" p={2}>
          <Heading size="md" data-testid="terms-conditions-title">
            Syarat dan Ketentuan
          </Heading>
          <Box
            dangerouslySetInnerHTML={{ __html: loyaltyDetailData?.term_and_condition || '' }}
            fontSize="sm"
            width="full"
            data-testid="terms-conditions-content"
            p={2}
            minHeight="30vh"
          />
        </VStack>
      </VStack>

      <Box
        width="100%"
        bg="white"
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.16)"
        data-testid="cta-button-container"
      >
        <Button
          width="100%"
          height="48px"
          bg="#038767"
          color="white"
          borderRadius="8px"
          fontWeight={500}
          fontSize="md"
          letterSpacing="0.5px"
          onClick={onClose}
          data-testid="cta-button"
        >
          Tutup
        </Button>
      </Box>
    </Box>
  );
};