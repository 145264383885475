import { dataLayer } from "src/utils/tracking";

export function trackOpenPointsPage(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "button_click",
      eventCategory: "points",
      buttonName: "Buka Halaman Poin",
      buttonPurpose: "Check Poin",
      pageTitle: "Loyalty Points",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

export function trackRedeemPoints(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "button_click",
      eventCategory: "points",
      buttonName: "Tukar",
      buttonPurpose: "points_redemption",
      pageTitle: "Loyalty Points",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

export function trackCheckTermsAndConditions(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "button_click",
      eventCategory: "points",
      buttonName: "Cek S&K Hadiah",
      buttonPurpose: "point_redemption",
      pageTitle: "Loyalty Points",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

export function trackConfirmRedeemPoints(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "button_click",
      eventCategory: "points",
      buttonName: "Tukarkan Poin Saya",
      buttonPurpose: "points_redemption",
      pageTitle: "Loyalty Points",
    },
  };
  dataLayer(tagManagerProps, authToken);
}