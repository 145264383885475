import { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";

import { isLoyaltyActivated } from '../constant';
import { trackOpenPointsPage } from '../utils/tracker';

export const useLoyaltyState = (authToken: string = "") => {
    const [isActivated, setIsActivated] = useState(false);
    const [cookies, setCookie] = useCookies([isLoyaltyActivated]);
  
    const checkLoyaltyActivation = () => {
      if (cookies.isLoyaltyActivated) setIsActivated(true);
    };
  
    useEffect(() => {
      checkLoyaltyActivation();
    }, []);
  
    const handleStartLoyalty = () => {
      setIsActivated(true);
      trackOpenPointsPage(authToken)
  
      setCookie(isLoyaltyActivated, true, {
        path: '/',
      });
    };
  
    return { isActivated, handleStartLoyalty };
  };